import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from "lodash";
import { JSONTree } from "react-json-tree";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";
import config from "../config";
import { FaSpinner, FaCopy } from "react-icons/fa";

function AttemptsWithCommands({ agent = null, googleAuthToken }) {
  const [llmCalls, setLlmCalls] = useState([]);
  const [fetchedLlmCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [showFullPrompt, setShowFullPrompt] = useState(false);
  const popupRef = useRef(null);
  const [combinedEvents, setCombinedEvents] = useState([]);
  const [selectedOutput, setSelectedOutput] = useState(null);
  const [showOutputPopup, setShowOutputPopup] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      if (!agent) {
        setCombinedEvents([]);
        setHasFetched(false);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      if (agent.function_name) {
        // For observables, format the data we already have
        const observableData = [
          {
            ...agent,
            type: "observable",
            created_at: agent.created_at,
            function_name: agent.function_name,
            output: agent.output,
            description: agent.description,
            trigger_observable_id: agent.trigger_observable_id,
            workflow_instance_id: agent.workflow_instance_id,
          },
        ];
        setCombinedEvents(observableData);
        setHasFetched(true);
        setIsLoading(false);
      } else if (agent.output) {
        // For outputs, format the data we already have
        const outputData = [
          {
            ...agent,
            type: "output",
            created_at: agent.created_at,
            output: agent.output,
          },
        ];
        setCombinedEvents(outputData);
        setHasFetched(true);
        setIsLoading(false);
      } else if (agent.error) {
        // For errors, format the data we already have
        const errorData = [
          {
            ...agent,
            type: "error",
            created_at: agent.created_at,
            errorMessage: agent.error,
          },
        ];
        setCombinedEvents(errorData);
        setHasFetched(true);
        setIsLoading(false);
      } else {
        // Existing agent data fetching logic
        Promise.all([
          axios.get(`${config.apiBaseUrl}/llm_calls/${agent.id}`, {
            headers: { Authorization: `Bearer ${googleAuthToken}` },
          }),
          axios.get(`${config.apiBaseUrl}/actions/${agent.id}`, {
            headers: { Authorization: `Bearer ${googleAuthToken}` },
          }),
        ])
          .then(([llmResponse, actionsResponse]) => {
            if (!llmResponse || !llmResponse.data) {
              console.error("LLM response is undefined or missing data");
              throw new Error("Invalid LLM response");
            }
            if (!actionsResponse || !actionsResponse.data) {
              console.error("Actions response is undefined or missing data");
              throw new Error("Invalid actions response");
            }

            const llmCalls = llmResponse.data.map((call) => ({
              ...call,
              type: "llm_call",
            }));
            const actions = actionsResponse.data.map((action) => ({
              ...action,
              type: "action",
            }));

            const combined = [...llmCalls, ...actions].sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);

              // Compare dates
              if (dateA.toDateString() !== dateB.toDateString()) {
                return dateB - dateA; // Most recent day first
              }

              // If same day, sort chronologically within the day
              return dateA - dateB;
            });

            setCombinedEvents(combined);
            setHasFetched(true);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
            setError("Error fetching data: " + error.message);
            setIsLoading(false);
          });
      }
    };

    fetchData();
  }, [agent, googleAuthToken]);

  const formatCompletion = (completion) => {
    let completionObj = {};
    try {
      completionObj = JSON.parse(completion);
      for (let key in completionObj) {
        try {
          completionObj[key] = JSON.parse(completionObj[key]);
        } catch (e) {
          // not a JSON string, leave it as is
        }
      }
      delete completionObj.text;
      delete completionObj.write_string;
      delete completionObj.commands;
    } catch (e) {
      completionObj = completion;
    }

    // Return the raw string instead of highlighted HTML
    return JSON.stringify(completionObj, null, 2);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowFullPrompt(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!_.isEqual(fetchedLlmCalls, llmCalls)) {
      setIsLoading(true);
      setLlmCalls(fetchedLlmCalls);
      setIsLoading(false);
      setHasFetched(true);
    }
  }, [llmCalls, fetchedLlmCalls]);

  const toggleFullPrompt = (event, prompt) => {
    event.preventDefault();
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    setSelectedPrompt(prompt);
    setShowFullPrompt(!showFullPrompt);
  };

  const toggleOutputPopup = (event, output) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (output.length > 2500) {
      setSelectedOutput(output);
      setShowOutputPopup(!showOutputPopup);
    }
  };

  const renderEvent = (event) => {
    const isUrl = (string) => {
      try {
        const url = new URL(string);
        // Only consider it a URL if it has a valid protocol (http or https)
        return url.protocol === "http:" || url.protocol === "https:";
      } catch (_) {
        return false;
      }
    };

    if (event.type === "observable") {
      const outputLength = event.output?.length || 0;
      const shouldTruncate = outputLength > 2500;
      const displayOutput = shouldTruncate
        ? event.output.slice(0, 2500) + "..."
        : event.output;

      return (
        <button
          className="attempt"
          onClick={(e) => toggleOutputPopup(e, event.output)}
        >
          <strong>Observable ID:</strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          <strong>Function Name:</strong> {event.function_name}
          <br />
          {event.description && (
            <>
              <strong>Description:</strong> {event.description}
              <br />
            </>
          )}
          {event.trigger_observable_id && (
            <>
              <strong>Triggered By Observable:</strong>{" "}
              {event.trigger_observable_id}
              <br />
            </>
          )}
          {event.output && (
            <>
              <strong>Output:</strong>
              <br />
              <br />
              <div style={{ position: "relative" }}>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    border: "1px solid black",
                    backgroundColor: "white",
                    margin: 0,
                    paddingRight: "32px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    minHeight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <code>{displayOutput}</code>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(event.output);
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)", // Center vertically
                      right: "8px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: "4px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "unset",
                    }}
                    title="Copy to clipboard"
                  >
                    <FaCopy size={14} style={{ color: "#666" }} />
                  </button>
                </pre>
              </div>
            </>
          )}
          {selectedOutput === event.output &&
            showOutputPopup &&
            event.output.length > 2500 && (
              <div
                className="popup"
                ref={popupRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowOutputPopup(false);
                }}
              >
                <div
                  className="popup-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3>Full Output:</h3>
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    <code>{event.output}</code>
                  </pre>
                  <button
                    className="close-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOutputPopup(false);
                    }}
                  >
                    <strong>Close</strong>
                  </button>
                </div>
              </div>
            )}
        </button>
      );
    } else if (event.type === "llm_call") {
      const formattedCompletion = formatCompletion(event.completion);
      return (
        <button
          className={`attempt attempt-${event.status}`}
          onClick={(e) => toggleFullPrompt(e, event.input)}
        >
          <strong>LLM Call ID: </strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          <strong>Model Name:</strong> {event.model_name}
          <br />
          <strong>Temperature:</strong> {event.temperature}
          <br />
          <strong>Input Tokens:</strong> {event.input_tokens}
          <br />
          <strong>Completion Tokens:</strong> {event.completion_tokens}
          <br />
          <strong>Cost: </strong>$
          {Number(event.cost)
            .toFixed(6)
            .replace(/\.?0+$/, "")}
          <br />
          <strong>Triggered By:</strong>{" "}
          {event.trigger_action_id !== null
            ? `action ID ${event.trigger_action_id}`
            : event.trigger_workflow_instance_id !== null
              ? `workflow ID ${event.trigger_workflow_instance_id}`
              : event.trigger_observable_id !== null
                ? `observable ID ${event.trigger_observable_id}`
                : "N/A"}
          <br />
          <br />
          <strong>Completion:</strong>
          <br />
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                position: "relative",
                paddingRight: "32px",
              }}
            >
              <code
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(
                    formattedCompletion,
                    Prism.languages.json,
                    "json"
                  ),
                }}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(formattedCompletion);
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
          {selectedPrompt === event.input && showFullPrompt && (
            <div
              className="popup"
              ref={popupRef}
              onClick={(e) => {
                e.stopPropagation();
                setShowFullPrompt(false);
              }}
              style={{ zIndex: 9999 }}
            >
              <div
                className="popup-content"
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <h3>Full Prompt:</h3>
                <JSONTree
                  data={JSON.parse(event.input)}
                  theme={theme}
                  invertTheme={false}
                  shouldExpandNodeInitially={() => true}
                />
                <button
                  className="close-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFullPrompt(false);
                  }}
                >
                  <strong>Close</strong>
                </button>
              </div>
            </div>
          )}
        </button>
      );
    } else if (event.type === "action") {
      const { type, ...actionWithoutType } = event;
      return (
        <button className={`attempt attempt-${event.status}`}>
          <strong>Action ID:</strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          <strong>LLM Call ID:</strong> {event.llm_call_id || "N/A"}
          <br />
          <strong>Action:</strong>
          <br />
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                position: "relative",
                paddingRight: "32px",
              }}
            >
              <code
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(
                    JSON.stringify(actionWithoutType, null, 2),
                    Prism.languages.json,
                    "json"
                  ),
                }}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    JSON.stringify(actionWithoutType, null, 2)
                  );
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
        </button>
      );
    } else if (event.type === "output") {
      return (
        <button className="attempt">
          <strong>Output Event ID:</strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          {event.key && (
            <>
              <strong>Key:</strong> {event.key}
              <br />
            </>
          )}
          <strong>Output:</strong>
          <br />
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                paddingRight: "32px",
                minHeight: "36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <code>
                {isUrl(event.output) ? (
                  <a
                    href={event.output}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.output}
                  </a>
                ) : (
                  event.output
                )}
              </code>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(event.output);
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
        </button>
      );
    } else if (event.type === "error") {
      return (
        <button className="attempt">
          <strong>Error Event ID:</strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          <strong>Error Message:</strong>
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                paddingRight: "32px",
              }}
            >
              <code>{event.errorMessage}</code>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(event.errorMessage);
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
        </button>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="AttemptsWithCommands">
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="AttemptsWithCommands">{error}</div>;
  }

  const theme = {
    base00: "#f7f7f7" /* background color */,
    base03: "#f7f7f7" /* item number color */,
    base0B: "#2b2b2b" /* text color */,
    base0D: "#247BA0" /* key color */,
  };

  return (
    <div className="AttemptsWithCommands">
      {combinedEvents.map((event) => (
        <div key={`${event.type}-${event.id}`}>
          {renderEvent(event)}
          {selectedPrompt === event.input && showFullPrompt && (
            <div
              className="popup"
              ref={popupRef}
              onClick={(e) => {
                e.stopPropagation();
                setShowFullPrompt(false);
              }}
              style={{ zIndex: 9999 }}
            >
              <div
                className="popup-content"
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <h3>Full Prompt:</h3>
                <JSONTree
                  data={JSON.parse(event.input)}
                  theme={theme}
                  invertTheme={false}
                  shouldExpandNodeInitially={() => true}
                />
                <button
                  className="close-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFullPrompt(false);
                  }}
                >
                  <strong>Close</strong>
                </button>
              </div>
            </div>
          )}{" "}
        </div>
      ))}
    </div>
  );
}

export default AttemptsWithCommands;
