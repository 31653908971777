import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";

const WORKFLOW_STATES = ["active", "paused", "archived"];

function AdminPage({ googleAuthToken }) {
  const [name, setName] = useState("");
  const [apiKeyTag, setApiKeyTag] = useState("");
  const [addtionalApiKeyTag, setAddtionalApiKeyTag] = useState("");
  const [newCustomerResult, setNewCustomerResult] = useState("");
  const [additionalKeyResult, setAdditionalKeyResult] = useState("");
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customers, setCustomers] = useState([]);
  const [apiKeyToValidate, setApiKeyToValidate] = useState("");
  const [validationResult, setValidationResult] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [updateDescriptionResult, setUpdateDescriptionResult] = useState("");
  const [workflowCustomerId, setWorkflowCustomerId] = useState("");
  const [workflowDisplayName, setWorkflowDisplayName] = useState("");
  const [hasDescriptionChanged, setHasDescriptionChanged] = useState(false);
  const [hasDisplayNameChanged, setHasDisplayNameChanged] = useState(false);
  const [updateResult, setUpdateResult] = useState("");
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [workflowState, setWorkflowState] = useState("");
  const [hasStateChanged, setHasStateChanged] = useState(false);

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    if (env !== "dev") {
      switchEnvironment("dev");
    }
  }, []);

  useEffect(() => {
    // Reset selections when environment changes
    setWorkflowCustomerId("");
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setUpdateDescriptionResult("");
  }, [env]);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer`,
        {
          name,
          apiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setNewCustomerResult(
        `Customer added successfully. API Key: ${response.data.apiKey}`
      );
    } catch (error) {
      setNewCustomerResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  async function addCustomerKey(customerId) {
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer key to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer-key`,
        {
          customerId,
          tag: addtionalApiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setAdditionalKeyResult(`New API Key added: ${response.data.apiKey}`);
    } catch (error) {
      setAdditionalKeyResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  const validateApiKey = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/validate-api-key`,
        {
          apiKey: apiKeyToValidate,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setValidationResult(
        `Valid API key for customer: ${response.data.customerName} (Tag: ${response.data.tag})`
      );
    } catch (error) {
      setValidationResult(`Invalid API key`);
    }
  };

  const fetchWorkflows = async (customerId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/customer-workflows/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const workflowObjects = response.data.map((workflow) => ({
        id: workflow.id,
        name: workflow.name,
        description: workflow.description,
        display_name: workflow.display_name,
        state: workflow.state,
      }));
      setWorkflows(workflowObjects);
      return workflowObjects;
    } catch (error) {
      console.error("Error fetching workflows:", error);
      return [];
    }
  };

  const handleCustomerChange = (e) => {
    const customerId = e.target.value;
    setCustomerIdInput(customerId);
  };

  const handleWorkflowCustomerChange = (e) => {
    const customerId = e.target.value;
    setWorkflowCustomerId(customerId);
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setWorkflowDisplayName("");
    setUpdateDescriptionResult("");
    setVersions([]);
    setSelectedVersion("");

    if (workflowCustomerId) {
      fetchWorkflows(workflowCustomerId);
    }
  };

  useEffect(() => {
    if (workflowCustomerId) {
      fetchWorkflows(workflowCustomerId);
    }
  }, [workflowCustomerId]);

  const handleWorkflowChange = (e) => {
    const workflowName = e.target.value;
    setSelectedWorkflow(workflowName);
    const workflow = workflows.find((w) => w.name === workflowName);
    setWorkflowDescription(workflow?.description || "");
    setWorkflowDisplayName(workflow?.display_name || "");
    setWorkflowState(workflow?.state || "");
    setHasDescriptionChanged(false);
    setHasDisplayNameChanged(false);
    setHasStateChanged(false);
    setUpdateResult("");
    setSelectedVersion("");

    if (workflow) {
      fetchWorkflowVersions(workflow.id);
    } else {
      setVersions([]);
    }
  };

  const handleDescriptionChange = (e) => {
    setWorkflowDescription(e.target.value);
    setHasDescriptionChanged(true);
  };

  const handleDisplayNameChange = (e) => {
    setWorkflowDisplayName(e.target.value);
    setHasDisplayNameChanged(true);
  };

  const handleStateChange = (e) => {
    setWorkflowState(e.target.value);
    setHasStateChanged(true);
  };

  const updateWorkflowDetails = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to update this workflow in the production environment?"
      )
    ) {
      return;
    }

    try {
      const workflow = workflows.find((w) => w.name === selectedWorkflow);
      if (!workflow) return;

      const updates = [];

      if (hasDescriptionChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-description/${workflow.id}`,
            { description: workflowDescription },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (hasDisplayNameChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-display-name/${workflow.id}`,
            { display_name: workflowDisplayName },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (hasStateChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-state/${workflow.id}`,
            { state: workflowState },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (updates.length > 0) {
        await Promise.all(updates);
        setUpdateResult("Workflow details updated successfully");

        // Refresh workflows
        if (workflowCustomerId) {
          const updatedWorkflows = await fetchWorkflows(workflowCustomerId);
          const updatedWorkflow = updatedWorkflows.find(
            (w) => w.name === selectedWorkflow
          );
          setWorkflowDescription(updatedWorkflow?.description || "");
          setWorkflowDisplayName(updatedWorkflow?.display_name || "");
          setWorkflowState(updatedWorkflow?.state || "");

          if (updatedWorkflow) {
            await fetchWorkflowVersions(updatedWorkflow.id);
          }
        }

        setHasDescriptionChanged(false);
        setHasDisplayNameChanged(false);
        setHasStateChanged(false);
      }
    } catch (error) {
      setUpdateResult(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  const fetchWorkflowVersions = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-versions/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setVersions(response.data);
    } catch (error) {
      console.error("Error fetching workflow versions:", error);
    }
  };

  const handleVersionChange = async (e) => {
    const versionId = e.target.value;
    setSelectedVersion(versionId);
  };

  return (
    <div className="AdminPage">
      <h1>Admin Tools</h1>
      <EnvSwitcher onEnvironmentSwitch={switchEnvironment} currentEnv={env} />
      <div className="container">
        <h2>Add New Customer</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              placeholder="Customer Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            <input
              type="text"
              placeholder="API Key Tag ('default' if left blank)"
              value={apiKeyTag}
              onChange={(e) => setApiKeyTag(e.target.value)}
            />
          </label>
          <button type="submit">Add Customer</button>
        </form>
        {newCustomerResult && <div className="result">{newCustomerResult}</div>}
      </div>
      <div className="container">
        <h2>Add Additional Key</h2>
        <div>
          <label>
            <select
              value={customerIdInput}
              onChange={(e) => setCustomerIdInput(e.target.value)}
            >
              <option value="">Select a customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            <input
              type="text"
              placeholder="API Key Tag"
              value={addtionalApiKeyTag}
              onChange={(e) => setAddtionalApiKeyTag(e.target.value)}
            />
          </label>
          <br />
          <button
            type="submit"
            onClick={() => addCustomerKey(customerIdInput, addtionalApiKeyTag)}
            disabled={!customerIdInput || !addtionalApiKeyTag}
          >
            Add Customer Key
          </button>
        </div>
        {additionalKeyResult && (
          <div className="result">{additionalKeyResult}</div>
        )}
      </div>
      <div className="container">
        <h2>Validate API Key</h2>
        <form onSubmit={validateApiKey}>
          <label>
            <input
              type="text"
              placeholder="Enter API Key to validate"
              value={apiKeyToValidate}
              onChange={(e) => setApiKeyToValidate(e.target.value)}
              required
            />
          </label>
          <button type="submit">Validate Key</button>
        </form>
        {validationResult && <div className="result">{validationResult}</div>}
      </div>
      <div className="container">
        <h2>Edit Workflow Details</h2>
        <div>
          <label>
            <select
              value={workflowCustomerId}
              onChange={handleWorkflowCustomerChange}
            >
              <option value="">Select a customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            <select
              value={selectedWorkflow}
              onChange={handleWorkflowChange}
              disabled={!workflowCustomerId}
            >
              <option value="">Select Workflow</option>
              {workflows.map((workflow) => (
                <option key={workflow.id} value={workflow.name}>
                  {workflow.name} ({workflow.state || "unknown"})
                </option>
              ))}
            </select>
          </label>
          <br />
          <form onSubmit={updateWorkflowDetails}>
            <textarea
              placeholder="Workflow Description"
              value={workflowDescription}
              onChange={handleDescriptionChange}
              disabled={!selectedWorkflow}
              rows={4}
              style={{ resize: "vertical" }}
            />
            <br />
            <textarea
              placeholder="Workflow Display Name"
              value={workflowDisplayName}
              onChange={handleDisplayNameChange}
              disabled={!selectedWorkflow}
              rows={4}
              style={{ resize: "vertical" }}
            />
            <br />
            <select
              value={workflowState}
              onChange={handleStateChange}
              disabled={!selectedWorkflow}
            >
              <option value="">Select State</option>
              {WORKFLOW_STATES.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <div style={{ marginTop: "20px" }}>
              <select
                value={selectedVersion}
                onChange={handleVersionChange}
                disabled={!selectedWorkflow}
              >
                <option value="">Select Version</option>
                {versions.map((version) => (
                  <option key={version.id} value={version.id}>
                    Version {version.version}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              disabled={
                !selectedWorkflow ||
                (!hasDescriptionChanged &&
                  !hasDisplayNameChanged &&
                  !hasStateChanged)
              }
            >
              Update Workflow Details
            </button>
          </form>
          {updateResult && <div className="result">{updateResult}</div>}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
