import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomerCosts({ googleAuthToken }) {
  const [customerCosts, setCustomerCosts] = useState([]);
  const [weeklyCosts, setWeeklyCosts] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { env } = useContext(EnvironmentContext);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [actionCounts, setActionCounts] = useState({});
  const [dateRange, setDateRange] = useState("7days");
  const [showCustomRange, setShowCustomRange] = useState(false);

  const fetchCustomerCosts = async () => {
    setLoading(true);
    setError(null);
    setWeeklyCosts({});
    setSelectedCustomer(null);

    try {
      const response = await axios.get(`${config.apiBaseUrl}/customer-costs`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomerCosts(response.data);
    } catch (error) {
      console.error("Error fetching customer costs:", error);
      setCustomerCosts([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchWeeklyCosts = async (customerName) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/weekly-customer-costs`,
        {
          params: { customer_name: customerName },
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const customerData = response.data.find(
        (c) => c.customer_name === customerName
      );
      setWeeklyCosts((prev) => ({
        ...prev,
        [customerName]: customerData?.weekly_costs || [],
      }));
    } catch (error) {
      console.error("Error fetching weekly costs:", error);
    }
  };

  const fetchWorkflows = async (customerName) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/customer-workflows/${selectedCustomer}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setWorkflows(response.data);
    } catch (error) {
      console.error("Error fetching workflows:", error);
    }
  };

  const fetchActionCounts = async () => {
    if (!selectedCustomer || !selectedWorkflow) return;

    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflows/${selectedWorkflow}/action-counts`,
        {
          params: {
            customer_id: selectedCustomer,
            start_date: startDate?.toISOString().split("T")[0],
            end_date: endDate?.toISOString().split("T")[0],
          },
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const formattedActions = response.data.actions.map((action) => ({
        function_name: action.llm_callable ? (
          <>
            {action.function_name}{" "}
            <span style={{ color: "#28a745" }}> - (LLM Callable)</span>
          </>
        ) : (
          action.function_name
        ),
        action_count: action.count,
      }));
      setActionCounts({
        actions: formattedActions,
        totalInstances: response.data.total_number_of_workflow_instances,
      });
    } catch (error) {
      console.error("Error fetching action counts:", error);
    }
  };

  const handleRowClick = (customer) => {
    if (selectedCustomer === customer.id) {
      setSelectedCustomer(null);
      setWorkflows([]);
      setSelectedWorkflow("");
      setActionCounts({});
    } else {
      setSelectedCustomer(customer.id);
      setActionCounts({});
      setSelectedWorkflow("");
      if (!weeklyCosts[customer.customer_name]) {
        fetchWeeklyCosts(customer.customer_name);
      }
    }
  };

  const getDateRange = (range) => {
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    let start = new Date();

    switch (range) {
      case "7days":
        start.setDate(start.getDate() - 7);
        start.setHours(0, 0, 0, 0);
        return { start, end };
      case "30days":
        start.setDate(start.getDate() - 30);
        start.setHours(0, 0, 0, 0);
        return { start, end };
      case "alltime":
        return { start: null, end: null };
      default:
        return { start: null, end: null };
    }
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    setShowCustomRange(value === "custom");

    if (value !== "custom") {
      const { start, end } = getDateRange(value);
      setStartDate(start);
      setEndDate(end);
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      fetchWorkflows();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    fetchCustomerCosts();
  }, [env, googleAuthToken]);

  useEffect(() => {
    handleDateRangeChange("7days");
  }, []);

  if (loading) {
    return <div>Loading customer costs...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="CustomerCosts">
      <h1>Customer Costs</h1>
      <EnvSwitcher onEnvironmentSwitch={fetchCustomerCosts} currentEnv={env} />
      <div className="costs-container">
        <table>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Total Cost</th>
              <th>Total Runs</th>
              <th>Average Cost per Run</th>
            </tr>
          </thead>
          <tbody>
            {customerCosts.map((customer) => (
              <React.Fragment key={customer.customer_name}>
                <tr
                  onClick={() => handleRowClick(customer)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{customer.customer_name}</td>
                  <td>${Number(customer.total_cost).toFixed(2)}</td>
                  <td>{customer.total_runs}</td>
                  <td>
                    $
                    {customer.total_runs > 0
                      ? (customer.total_cost / customer.total_runs).toFixed(2)
                      : "0.00"}
                  </td>
                </tr>
                {selectedCustomer === customer.id &&
                  weeklyCosts[customer.customer_name] && (
                    <tr>
                      <td colSpan="4">
                        <div style={{ padding: "20px" }}>
                          <div
                            style={{
                              width: 800, // Match chart width
                              marginBottom: "20px",
                            }}
                          >
                            <h3 style={{ textAlign: "center" }}>
                              {customer.customer_name} - Weekly Costs and Runs
                            </h3>
                          </div>
                          <LineChart
                            width={800}
                            height={300}
                            data={weeklyCosts[customer.customer_name]}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="week"
                              tickFormatter={(value) => {
                                const year = parseInt(value.substring(0, 4));
                                const week = parseInt(value.substring(6));
                                const date = new Date(
                                  year,
                                  0,
                                  1 + (week - 1) * 7
                                );
                                return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
                              }}
                            />
                            <YAxis
                              yAxisId="cost"
                              orientation="left"
                              stroke="#8884d8"
                              label={{
                                value: "Cost ($)",
                                angle: -90,
                                position: "insideLeft",
                                offset: 0,
                                style: {
                                  textAnchor: "middle",
                                },
                              }}
                              tickFormatter={(value) => {
                                const formatted = Number(value).toFixed(2);
                                return `$${formatted.replace(/\.?0+$/, "")}`;
                              }}
                            />
                            <YAxis
                              yAxisId="runs"
                              orientation="right"
                              stroke="#82ca9d"
                              label={{
                                value: "Number of Runs",
                                angle: 90,
                                position: "insideRight",
                                offset: 0,
                                style: {
                                  textAnchor: "middle",
                                },
                              }}
                            />
                            <Tooltip
                              formatter={(value, name) => {
                                if (name === "Cost ($)") {
                                  return [`$${Number(value).toFixed(2)}`, name];
                                }
                                return [value, name];
                              }}
                            />
                            <Line
                              type="monotone"
                              dataKey="cost"
                              stroke="#8884d8"
                              name="Cost ($)"
                              yAxisId="cost"
                            />
                            <Line
                              type="monotone"
                              dataKey="runs"
                              stroke="#82ca9d"
                              name="Runs"
                              yAxisId="runs"
                            />
                          </LineChart>

                          <div
                            className="action-counts-container"
                            style={{ marginTop: "2rem" }}
                          >
                            <h3>Action Counts</h3>
                            <div
                              className="filters"
                              style={{
                                marginBottom: "1rem",
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <select
                                value={selectedWorkflow}
                                onChange={(e) =>
                                  setSelectedWorkflow(e.target.value)
                                }
                                style={{ padding: "0.5rem" }}
                              >
                                <option value="">Select Workflow</option>
                                {workflows.map((workflow) => (
                                  <option
                                    key={workflow.id}
                                    value={workflow.name}
                                  >
                                    {workflow.name}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={dateRange}
                                onChange={(e) =>
                                  handleDateRangeChange(e.target.value)
                                }
                                style={{ padding: "0.5rem" }}
                              >
                                <option value="7days">Last 7 Days</option>
                                <option value="30days">Last 30 Days</option>
                                <option value="alltime">All Time</option>
                                <option value="custom">Custom Range</option>
                              </select>

                              {showCustomRange && (
                                <>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                      const startOfDay = new Date(date);
                                      startOfDay.setHours(0, 0, 0, 0);
                                      setStartDate(startOfDay);
                                    }}
                                    placeholderText="Start Date"
                                    maxDate={endDate || new Date()}
                                    showTimeSelect={false}
                                  />

                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                      const endOfDay = new Date(date);
                                      endOfDay.setHours(0, 0, 0, 0);
                                      setEndDate(endOfDay);
                                    }}
                                    placeholderText="End Date"
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    showTimeSelect={false}
                                  />
                                </>
                              )}

                              <button
                                onClick={fetchActionCounts}
                                disabled={!selectedWorkflow}
                              >
                                Fetch Action Counts
                              </button>
                            </div>

                            {actionCounts.actions?.length > 0 && (
                              <>
                                <div style={{ marginBottom: "1rem" }}>
                                  <strong>Total Runs: </strong>
                                  {actionCounts.totalInstances}
                                </div>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Action Name</th>
                                      <th>Action Count</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {actionCounts.actions.map(
                                      (action, index) => (
                                        <tr key={index}>
                                          <td>{action.function_name}</td>
                                          <td>{action.action_count}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerCosts;
