import React, { useState, useEffect } from "react";
import "../App.css";
import config from "../config";
import { FaSpinner } from "react-icons/fa";

function Agents({
  run,
  onAgentClick,
  selectedObject,
  googleAuthToken,
  onViewChange,
}) {
  const [agents, setAgents] = useState([]);
  const [observables, setObservables] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [errors, setErrors] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [view, setView] = useState("agents");

  useEffect(() => {
    let intervalId = null;

    const fetchData = async () => {
      if (run) {
        try {
          // Fetch agents, observables, outputs, and errors
          const [
            agentsResponse,
            observablesResponse,
            outputsResponse,
            errorsResponse,
          ] = await Promise.all([
            fetch(`${config.apiBaseUrl}/agents/${run.id}`, {
              headers: { Authorization: `Bearer ${googleAuthToken}` },
            }),
            fetch(`${config.apiBaseUrl}/observables/${run.id}`, {
              headers: { Authorization: `Bearer ${googleAuthToken}` },
            }),
            fetch(`${config.apiBaseUrl}/workflow-outputs/${run.id}`, {
              headers: { Authorization: `Bearer ${googleAuthToken}` },
            }),
            fetch(`${config.apiBaseUrl}/workflow-errors/${run.id}`, {
              headers: { Authorization: `Bearer ${googleAuthToken}` },
            }),
          ]);

          const [agentsData, observablesData, outputsData, errorsData] =
            await Promise.all([
              agentsResponse.json(),
              observablesResponse.json(),
              outputsResponse.json(),
              errorsResponse.json(),
            ]);

          setAgents(agentsData);
          setObservables(observablesData);
          setOutputs(outputsData);
          setErrors(errorsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setHasLoaded(true);
        }
      } else {
        setAgents([]);
        setObservables([]);
        setOutputs([]);
        setErrors([]);
        setHasLoaded(true);
      }
    };

    fetchData();
    intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [run, googleAuthToken]);

  useEffect(() => {
    onViewChange(view);
  }, [view, onViewChange]);

  // Pass the observable runs when clicking an observable
  const handleObjectClick = (item) => {
    if (item.function_name) {
      // For observables, pass the runs data
      onAgentClick({
        ...item,
        runs: item.runs || [], // Include the runs data if it exists
      });
    } else if (item.output) {
      // For outputs, pass the runs data
      onAgentClick({
        ...item,
        runs: item.runs || [], // Include the runs data if it exists
      });
    } else if (item.error) {
      // For errors, pass the runs data
      onAgentClick({
        ...item,
        runs: item.runs || [], // Include the runs data if it exists
      });
    } else {
      // For regular agents, pass as is
      onAgentClick(item);
    }
  };

  if (!hasLoaded) {
    return (
      <div className="agents-container">
        <div className="header-with-toggle">
          <h2>Agents</h2>
        </div>
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="agents-container">
      <div className="header-with-toggle">
        <h2
          onClick={() =>
            setView(
              view === "agents"
                ? "observables"
                : view === "observables"
                  ? "outputs"
                  : view === "outputs"
                    ? "errors"
                    : "agents"
            )
          }
          style={{ cursor: "pointer" }}
        >
          {view.charAt(0).toUpperCase() + view.slice(1)}
        </h2>
      </div>
      <div className="agents-list">
        {view === "observables" ? (
          observables.length > 0 ? (
            observables.map((observable) => (
              <button
                key={observable.id}
                onClick={() => handleObjectClick(observable)}
                className={`agent ${
                  selectedObject && selectedObject.id === observable.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {observable.function_name
                    ? observable.function_name
                        .replace(/_/g, " ")
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : "Unnamed Observable"}
                </strong>
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No observables found for this run
            </div>
          )
        ) : view === "outputs" ? (
          outputs.length > 0 ? (
            outputs.map((output) => (
              <button
                key={output.id}
                onClick={() => handleObjectClick(output)}
                className={`agent ${
                  selectedObject && selectedObject.id === output.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {(output.output || "Unnamed Output").slice(0, 22) +
                    (output.output.length > 25 ? "..." : "")}
                </strong>
                <p>{output.description}</p>
                {output.key && (
                  <p
                    style={{
                      fontSize: "0.8em",
                      color: "#666",
                      marginTop: "4px",
                      marginBottom: "0",
                    }}
                  >
                    {output.key}
                  </p>
                )}
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No outputs found for this run
            </div>
          )
        ) : view === "errors" ? (
          errors.length > 0 ? (
            errors.map((error) => (
              <button
                key={error.id}
                onClick={() => handleObjectClick(error)}
                className={`agent ${
                  selectedObject && selectedObject.id === error.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {(error.error || "Unnamed Error").slice(0, 22) +
                    (error.error.length > 25 ? "..." : "")}
                </strong>
                <p>{error.details}</p>
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No errors found for this run
            </div>
          )
        ) : agents.length > 0 ? (
          agents.map((agent) => {
            // Clean agent name
            let cleanedName = agent.agent_name
              ? agent.agent_name
                  .replace(/_/g, " ")
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : "Unknown Agent";

            return (
              <button
                key={agent.id}
                onClick={() => handleObjectClick(agent)}
                className={`agent ${
                  selectedObject && selectedObject.id === agent.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>{cleanedName}</strong>
              </button>
            );
          })
        ) : (
          <div className="agent-placeholder">No agents found for this run</div>
        )}
      </div>
    </div>
  );
}

export default Agents;
